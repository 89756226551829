const classCheck = document.getElementsByClassName('promo-banner-static-active').length > 0;

if(classCheck)
{

console.log('Klasse existiert');  

var staticBanner = $('#promoBannerStaticID'),
    extra = 10; // In case you want to trigger it a bit sooner than exactly at the bottom.

staticBanner.css({ opacity: '1', display: 'block' });

$(window).scroll(function() {

   var scrolledLength = ( $(window).height() + extra ) + $(window).scrollTop(),
       documentHeight = $(document).height();


    //console.log( 'Scroll length: ' + scrolledLength + ' Document height: ' + documentHeight )


   if( scrolledLength >= documentHeight ) {

       staticBanner
          .addClass('bottom')
          .stop().animate({ bottom: '0', opacity: '0' }, 300);

   }
   else if ( scrolledLength <= documentHeight && staticBanner.hasClass('bottom') ) {           
        staticBanner
           .removeClass('bottom')
           .stop().animate({ bottom: '0', opacity: '1' }, 300);

   }
});

}