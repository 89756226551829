$(function () {
  let defaultConfiguration = {
    prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="slick-next">Next</button>',
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    swipeToSlide: true,
    touchThreshold: 5,
    useCSS: true,
    useTransform: true,
    waitForAnimate: true,
  }

  let visibilityObserver = new IntersectionObserver((entries) => {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      
      if (entry.isIntersecting === true) {
        $(entry.target).slick('slickPlay');
      } else {
        $(entry.target).slick('slickPause');
      }
    }
  });

  $('.obis-site-thumbsslider-main').each(function (i, e) {
    let element = $(this);
    let carousel = element.children('.carousel');

    let config = Object.assign({}, defaultConfiguration, {
      dots: element.data('dots'),
      arrows: element.data('arrows'),
      infinite: element.data('infinite'),
      speed: element.data('speed'),
      autoplaySpeed: element.data('autoplay-speed'),
      autoplay: element.data('autoplay'),
      slidesToShow: element.data('slides-to-show'),
      slidesToScroll: element.data('slides-to-scroll'),
      pauseOnFocus: element.data('pause-on-focus'),
      pauseOnHover: element.data('pause-on-hover'),
      pauseOnDotsHover: element.data('pause-on-dots-hover'),
      touchMove: element.data('touch-move'),
      draggable: element.data('draggable'),
      swipe: element.data('swipe'),
      asNavFor: element.data('as-nav-for'),
    });

    if (isset((prevArrow = element.data('prev-arrow')))) {
      config.prevArrow = '<button type="button" class="slick-prev arrow-custom">' + prevArrow + '</button>';
    }

    if (isset((nextArrow = element.data('next-arrow')))) {
      config.nextArrow = '<button type="button" class="slick-next arrow-custom">' + nextArrow + '</button>';
    }
    
    this.carousel = carousel.slick(config);

    if (config.autoplay === true) {
      if (!Style.isInViewport(carousel)) {
        carousel.slick('slickPause');
      }

      visibilityObserver.observe(carousel[0]);
    }
  });
});