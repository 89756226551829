window.addEventListener('ajax_form_load_success', (event) => {
  let branchSelect = event.detail.find('select.branch-select');
  let selectedBranch = event.detail.data('selected-branch');

  console.log(branchSelect);
  console.log(selectedBranch);
  
  if (isset(branchSelect) && isset(selectedBranch, 'string')) {
    branchSelect.val(selectedBranch);
  }
});