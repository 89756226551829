$(function () {
  let defaultConfiguration = {
    prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="slick-next">Next</button>',
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    swipeToSlide: true,
    touchThreshold: 5,
    useCSS: true,
    useTransform: true,
    waitForAnimate: true,
  }

  let visibilityObserver = new IntersectionObserver((entries) => {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      
      if (entry.isIntersecting === true) {
        $(entry.target).slick('slickPlay');
      } else {
        $(entry.target).slick('slickPause');
      }
    }
  });

  $('.obis-site-brandslider').each(function (i, e) {
    let element = $(this);
    let carousel = element.children('.carousel');

    let config = Object.assign({}, defaultConfiguration, {
      infinite: element.data('infinite'),
      speed: element.data('speed'),
      autoplaySpeed: element.data('autoplay-speed'),
      autoplay: element.data('autoplay'),
      slidesToShow: element.data('slides-to-show'),
      slidesToScroll: element.data('slides-to-scroll'),
      cssEase: element.data('css-ease'),
      pauseOnFocus: element.data('pause-on-focus'),
      pauseOnHover: element.data('pause-on-hover'),
      touchMove: element.data('touch-move'),
      draggable: element.data('draggable'),
      swipe: element.data('swipe'),
      arrows: element.data('arrows'),
      dots: element.data('dots'),
      centerMode: element.data('centerMode'),
      centerPadding: (isset(element.data('center-padding'), 'number') ? parseInt(element.data('center-padding'))+'px' : '50px'),
      responsive: [
        {
          breakpoint: 4096,
          settings: {
            slidesToShow: 10,
            slidesToScroll: 10
          }
        },
        {
          breakpoint: 2048,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 7
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
    
    this.carousel = carousel.slick(config);

    if (config.autoplay === true) {
      if (!Style.isInViewport(carousel)) {
        carousel.slick('slickPause');
      }

      visibilityObserver.observe(carousel[0]);
    }
  });
});