document.afterLoad(() => {
  function handleChange(event) {
    let defaultNotice = document.querySelector('.contact-wrapper .default-notice');

    if (isset(event.target.value, 'string')) {
      let selectedContact = document.querySelector(`.contact-wrapper .obis-site-contactinfobox [data-email="${event.target.value}"]`);

      if (isset(selectedContact)) {
        $(defaultNotice).slideUp('fast');
        $(".contact-wrapper .obis-site-contactinfobox .item.show").collapse('hide');
        setTimeout(() => $(selectedContact).collapse('show'), 120);

        return;
      }
    }

    $(".contact-wrapper .obis-site-contactinfobox .item.show").collapse('hide');
    setTimeout(() => $(defaultNotice).slideDown('fast'), 120);
  }

  window.addEventListener('ajax_form_load_success', function (event) {
    if (event.detail.closest('footer').length > 0) {
      let contactSelectBox = event.detail.find('.branch-select').get(0);
      
      if (isset(contactSelectBox)) {
        contactSelectBox.removeEventListener('change', handleChange);
        contactSelectBox.addEventListener('change', handleChange);
      }
    }
  });
});
