$(() => {
  $.fn.ticker = function (options) {
    $.fn.ticker.defaults = {
      controls: false,
      interval: 3000,
      effect: "fadeIn",
      duration: 400,
    };
    var o = $.extend({}, $.fn.ticker.defaults, options);
    if (!this.length) return;
    return this.each(function () {
      var $ul = $(this),
        $items = $ul.find("li"),
        index = 0,
        paused = false,
        time;
      function start() {
        time = setInterval(function () {
          if (!paused) changeItem();
        }, o.interval);
      }
      function changeItem() {
        var $current = $items.eq(index);
        index++;
        if (index == $items.length) index = 0;
        var $next = $items.eq(index);
        if (o.effect == "fadeIn") {
          $current.fadeOut(function () {
            $next.fadeIn();
          });
        } else if (o.effect == "slideUp" || o.effect == "slideDown") {
          var h = $ul.height();
          var d = o.effect == "slideUp" ? 1 : -1;
          $current.animate({ top: -h * d + "px" }, o.duration, function () {
            $(this).hide();
          });
          $next.css({ display: "block", top: h * d + "px" });
          $next.animate({ top: 0 }, o.duration);
        }
      }
      function bindEvents() {
        $ul.hover(
          function () {
            paused = true;
          },
          function () {
            paused = false;
          }
        );
      }
      function init() {
        $items.not(":first").hide();
        if (o.effect == "slideUp" || o.effect == "slideDown") {
          $ul.css("position", "relative");
          $items.css("position", "absolute");
        }
        bindEvents();
        start();
      }
      init();
    });
  };

  if(!Neos.inBackend() && $(".sliding-quotes").children().length > 1) {
    $(".sliding-quotes").each(function () {
      $(this).ticker({ effect: "slideUp", interval: 7000 });
    });
  }
});